@import './../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  clear: both;
  position: relative;
}

.product-quickshop {
  @include font-smoothing;
  margin: 0 auto;
  width: 100%;
  background: $color-black;
  color: $color-silver;
  &--inverted-white {
    @include font-smoothing(none);
    background: $color-white;
    color: $color-black;
  }
  &__shade-picker {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  // applies only when quickshop is inside a colorBox overlay
  .qs-overlay-cbox & {
    //height: 100%;
    padding: 25px 0 20px 0;
    @include breakpoint($medium-up) {
      padding: 50px;
    }
  }
  @include breakpoint($medium-up) {
    min-height: 800px;
  }
}

.product-quickshop__wrapper {
  @include breakpoint($medium-up) {
    @include clearfix;
  }
}

.product-quickshop__image {
  text-align: center;
  position: relative;
  // overrides for quickshop
  .product-full__image {
    @include breakpoint($medium-up) {
      float: none;
      width: 100%;
      margin: 0;
    }
  }
  .product-full__image-carousel {
    max-width: 440px;
  }
  .product-full__carousel__thumbnail {
    img {
      border: none;
      border-bottom: 2px solid transparent;
    }
    &:hover {
      img {
        border: none;
        border-bottom: 2px solid $color-thumbnail-border-grey;
        .product-full--inverted-white &,
        .product-quickshop--inverted-white & {
          border-color: $color-silver;
        }
      }
    }
    &.active {
      cursor: default;
      img {
        border: none;
        border-bottom: 2px solid $color-thumbnail-border-grey;
        .product-full--inverted-white &,
        .product-quickshop--inverted-white & {
          border-color: $color-stone-gray;
        }
      }
    }
  }
  .product-full__image-thumbnails {
    @include breakpoint($medium-up) {
      padding-bottom: 40px;
    }
  }
  @include breakpoint($medium-up) {
    width: 56.83168%;
    float: #{$ldirection};
  }
}

.product-quickshop__detail {
  padding: 15px;
  @include breakpoint($medium-up) {
    width: 38.21782%;
    float: #{$rdirection};
    padding: 40px 0;
  }
}

.product-quickshop__header {
  margin: 0 0 10px 0;
  padding: 0 15px;
  width: 90%;
  @include breakpoint($medium-up) {
    width: 100%;
    margin-bottom: 15px;
    padding: 0;
  }
  a {
    color: $color-silver;
    &:hover {
      color: $color-silver;
    }
  }
  .product-quickshop--inverted-white & {
    a {
      color: $color-link;
      &:hover {
        color: $color-link-hover;
      }
    }
  }
}

.product-quickshop__name {
  @include font--edwardian-script;
  @include font-smoothing;
  font-size: 35px;
  line-height: 30px;
  margin: 0;
  @include breakpoint($medium-up) {
    margin-bottom: 16px;
    font-size: 50px;
    line-height: 45px;
  }
}

.product-quickshop__subline {
  margin: 3px 0 0 0;
}

.product-quickshop__size,
.product-quickshop__price {
  margin: 0 0 10px 0;
  font-weight: 500;
  @include breakpoint($medium-up) {
    margin-bottom: 20px;
  }
}

.product-quickshop__size {
  .product-sku-size {
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.product-quickshop__price {
  @include breakpoint($medium-up) {
    margin-bottom: 50px;
    font-size: 16px;
  }
  .product-sku-unit-price__value {
    font-size: 11px;
    font-weight: 400;
    @include breakpoint($medium-up) {
      font-size: 12px;
    }
  }
}

.product-quickshop__subheading {
  margin: 0 0 10px 0;
  @include breakpoint($medium-up) {
    margin-bottom: 25px;
  }
}

.product-quickshop__dropdown-wrapper {
  @include clearfix;
  margin: 0 0 15px 0;
  @include breakpoint($medium-up) {
    margin-bottom: 20px;
    padding: 5px 0 0 0;
  }
  select,
  a.selectBox {
    min-width: 100%;
    max-width: 100%;
    @include breakpoint($medium-up) {
      height: 50px;
      line-height: 50px;
    }
  }
}

.product-quickshop__sku {
  float: #{$ldirection};
  width: 75%;
  .sku-menu__onesize {
    padding-top: 10px;
    @include breakpoint($medium-up) {
      padding-top: 16px;
    }
  }
}

.product-quickshop__qty {
  float: #{$rdirection};
  width: 21%;
  a.selectBox--inverted {
    .selectBox-label {
      color: $color-white;
    }
  }
}

.product-quickshop__buttons {
  margin: 0 0 15px 0;
  .button--disabled {
    background: $color-charcoal;
    border: none;
    color: $color-white;
    cursor: default;
  }
}

.product-quickshop__offer {
  margin: 0 0 20px 0;
  text-align: center;
  .product-offer__text {
    @include font-smoothing(none);
    @include tracking(100);
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.product-quickshop__details-link {
  margin-top: 10%;
  .spp-link {
    color: $color-white;
  }
}

.product-quickshop__accordion {
  @include breakpoint($medium-up) {
    margin-top: 70px;
  }
}

// quickshop colorbox
.qs-overlay-cbox {
  .product-quickshop__close {
    display: none;
  }
  #cboxWrapper,
  #cboxLoadedContent {
    background: none;
  }
  #cboxLoadedContent {
    padding: 0;
  }
  #cboxClose {
    color: $color-light-stone-gray;
  }
}

.qs-overlay-cbox--inverted-white {
  #cboxClose {
    color: $color-stone-gray;
  }
}
